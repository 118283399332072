import Excel from 'exceljs';
import {
    converseCountyLinked,
    dunnCountyLinked, laramieCountyLinked, mountrailAndMcKenzieCountyLinked,
    weldCountyLinked
} from "./countyFormats";

export const exportFile = (data) => {

    let workbook = new Excel.Workbook();
    const sheet = workbook.addWorksheet(data.header[0].project, {
    pageSetup:{
        paperSize: 5,
        orientation:'landscape',
        printTitlesColumn: "1:8",
        fitToPage: true,
        fitToWidth: 1,
        fitToHeight: 0
    },
        views:
            [{state: 'frozen', ySplit:8}],

});

    switch (data.header[0].county) {
        case 'Dunn County, North Dakota':
            dunnCountyLinked(sheet, data)
            break;
        case 'Weld County, Colorado':
            weldCountyLinked(sheet, data)
            break;
        case 'Converse County, Wyoming':
            converseCountyLinked(sheet, data)
            break;
        case 'Mountrail County, North Dakota':
        case 'McKenzie County, North Dakota':
            mountrailAndMcKenzieCountyLinked(sheet, data)
            break;
        case 'Laramie County, Wyoming':
            laramieCountyLinked(sheet, data)
                break;
        default:
            weldCountyLinked(sheet, data);
    }



    workbook.xlsx.writeBuffer().then(function (bufferData) {
        const blob = new Blob([bufferData], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        const url = window.URL.createObjectURL(blob);
        const anchor = document.createElement("a");
        anchor.href = url;
        anchor.download = data.header[0].project + ".xlsx";
        anchor.click();
        window.URL.revokeObjectURL(url);
    });
};