import {Alert} from "@mui/material";
import LinearProgress from "@mui/material/LinearProgress";
import React, {useContext} from "react";
import {AppState} from "../../App";
import {useSignals} from "@preact/signals-react/runtime";
import {effect} from "@preact/signals-react";


const AlertBox = () => {
    useSignals();
    const appState = useContext(AppState)
    return (
        <>
        <div className="alertbox" style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
        }}>
            {appState.alertMessage.value !== "" ? <Alert variant="outlined"
                                                         severity={appState.alertSeverity.value}>{appState.alertMessage.value}</Alert> : <></>}
        </div>
    <div className="alertbox" style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    }}>
        {appState.alertProgress.value !== 0 ? <LinearProgress sx={{width: "80%", height: "50px"}} variant="determinate"
                                                              value={appState.alertProgress.value}/> : <></>}
    </div>
        </>
)
}
export default AlertBox
