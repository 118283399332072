import {queryForDoc} from "./queryForUniqueDoc";
import {addDocToCollection, updateDocInCollection, updateDocURLInCollection} from "../dbFunctions";
import {getDocumentCategoriesFromSheet} from "../../pages/Database/functions/getHeaderInfo";
import {extractDoc} from "../../pages/Database/functions/extractDoc";
import {getFullListForCounty} from "./getFullListForCounty";
import {selectedCounty} from "../../store/signalsStore";


export const queryForDuplicateDocuments = async (rows, showDuplicates, replaceAll, verifyAll, appState) => {
    appState.alertProgress.value = 20;

    const indexOf = getDocumentCategoriesFromSheet(rows);
    rows.map((document, index) => {
        const progressIncrement = (80 / rows.length * (index + 1))
        let documentToCheck = extractDoc(document, indexOf, index, verifyAll)


        if (documentToCheck.document !== undefined) {


            appState.alertMessage.value = "importing";
            queryForDoc(documentToCheck).then(id => {


                if (!id) {
                    addDocToCollection(documentToCheck).then((newDoc) => {
                        appState.importStats.value.added.push(newDoc)
                        updateDocURLInCollection(documentToCheck, newDoc.id).then(r => {
                        })
                    })

                } else {
                    updateDocInCollection(documentToCheck, id).then((oldDoc) => {
                        appState.importStats.value.updated.push({"old": oldDoc, "new": documentToCheck})
                        updateDocURLInCollection(documentToCheck, id).then(r => {
                        })
                    })

                }
                return null
            })
        }
        appState.alertProgress.value = progressIncrement + 20
        if (appState.alertProgress.value === 100) {
            appState.showImportResults.value = true
            console.log(appState.importStats.value)
        }

    })
    getFullListForCounty(selectedCounty.value)

}




