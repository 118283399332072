import {collection, getDocs, query, where} from "firebase/firestore";
import {db} from "../../services/firebase_config/firebase";
import {lastUpdate, numberOfDocs} from "../../store/signalsStore";

export const getCountyCollectionData = async (county, setDocs) => {
    const collectionRef = collection(db, "CountyCollectionData");
    const countyDataQuery = query(collectionRef,
        where("county", "==", county));
    const querySnapshot = await getDocs(countyDataQuery)
    if (!querySnapshot.empty) {
       let  queryResult =  querySnapshot.docs.map(doc => ({...doc.data(), id: doc.id}));
        console.log(queryResult[0].documents)
        if (queryResult[0] !== undefined) {
            numberOfDocs.value = queryResult[0].documents;
            setDocs(queryResult[0].documents)
    }
    }
};


