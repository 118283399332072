import {headerDetailsSignal} from "../../../store/signalsStore";

export const getDocumentCategoriesFromSheet = (rows) => {
    let documentDetails = {};

    rows.map((row, mainIndex) => {

        if (documentDetails.county === undefined) {
            documentDetails.county = row.find(county => county.includes("County"))
        }

        if (row.includes("COMMENTS")) {
            documentDetails =
                {...documentDetails, beginDocumentRowsIndex: mainIndex + 1}
            row.forEach((header, rowIndex) => {
                switch (header.toUpperCase()) {
                    case "PAGE NO." :
                        header = "page"
                        break;
                    case "RECEPT. NO.":
                        header = "reqNumber"
                        break;
                    case "DOC. NO.":
                        header = "reqNumber"
                        break;
                    case "DOC. #":
                        header = "reqNumber"
                        break;
                    case "COMMENTS":
                        header = "comments"
                        break;
                    case "DATED":
                        header = "fileDate"
                        break;
                    case "RECORDED":
                        header = "fileDate"
                        break;
                    case "REC. DATE":
                        header = "fileDate"
                        break;
                    case "DESCRIPTION":
                        header = "description"
                        break;
                    case "DOCUMENT":
                        header = "document"
                        break;
                    case "INSTRUMENT":
                        header = "document"
                        break;
                    case "DOCUMENT NO.":
                        header = "reqNumber"
                        break;
                    case "EFFECTIVE DATE":
                        header = "effectiveDate"
                        break;
                    case "EFFECTIVE":
                        header = "effectiveDate"
                        break;
                    case "GRANTOR":
                        header = "grantor"
                        break;
                    case "GRANTEE":
                        header = "grantee"
                        break;
                    case "INSTRUMENT TYPE":
                        header = "instrument"
                        break;
                    case "BOOK":
                        header = "book"
                        break;
                    case "BOOK ":
                        header = "book"
                        break;
                    case "PAGE":
                        header = "page"
                        break;
                    case "PAGE NO#":
                        header = "page"
                        break;
                    case "RECEIVED":
                        header = "receivedDate"
                        break;
                    case "WOL":
                        header = "wol"
                        break;
                    default:
                        return null;
                }
                documentDetails =
                    {...documentDetails, [header]: rowIndex}
            })
        }
        if (row.includes("LIMITATIONS")) {
            documentDetails =
                {...documentDetails, endDocumentRowsIndex: mainIndex - 1}
        }
        if (row.includes("LIMITATIONS") && documentDetails.county === "Converse County, Wyoming" || documentDetails.county === "Laramie County, Wyoming") {
            documentDetails =
                {...documentDetails, endDocumentRowsIndex: mainIndex}
        }
    })
console.log(documentDetails)
    return documentDetails
}

export const getHeaderDetails = (rows, fileName) => {
    let lastPage;
    rows.forEach((row, index) => {
        if (row[0] !== undefined && typeof row[0] === "string")
            if (row[0].toLowerCase().includes("page:")) {
                lastPage = row[0].split(": ")[1]

            }
    })
    headerDetailsSignal.value = {
        project: fileName ?? "",
        county: rows.find(row => row[0].includes("County"))[0] ?? "",
        townshipAndRange: rows.find(row => row[0].includes("Township")).toString() ?? "",
        section: rows.find(row => row[0].includes("Section")).toString() ?? "",
        date: rows.find(row => row[0].includes(" to ")).toString() ?? "",
        acreage: rows.find(row => row[0].includes("Acres")).toString() ?? "",
        client: rows[0][0].toLowerCase().split("title for ")[1] ?? "",
        lastPage: lastPage ?? ""
    }
    return {
        project: fileName ?? "",
        county: rows.find(row => row[0].includes("County"))[0] ?? "",
        townshipAndRange: rows.find(row => row[0].includes("Township")).toString() ?? "",
        section: rows.find(row => row[0].includes("Section")).toString() ?? "",
        date: rows.find(row => row[0].includes(" to ")).toString() ?? "",
        acreage: rows.find(row => row[0].includes("Acres")).toString() ?? "",
        client: rows[0][0].toLowerCase().split("title for ")[1] ?? "",
        lastPage: lastPage ?? ""
    }

}

export const getDocListHeaderDetails = (rows) => {
        let headerDetails = {}
    rows.map((row) => {
        if (row[0] === "Project") headerDetailsSignal.value.project = row[1]
        if (row[0] === "County") headerDetailsSignal.value.county = row[1]
        if (row[0] === "TRS") headerDetailsSignal.value.townshipAndRange = row[1]
        if (row[0] === "Section") headerDetailsSignal.value.section = row[1]
        if (row[0] === "Date Range for Report") headerDetailsSignal.value.date = row[1]
        if (row[0] === "Acreage") headerDetailsSignal.value.acreage = row[1]
        if (row[0] === "Last Page") headerDetailsSignal.value.lastPage = row[1]
        if (row[0] === "Client") headerDetailsSignal.value.client = row[1]
    })
        return headerDetails

}
