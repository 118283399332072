import React, {useContext, useEffect, useState} from "react";
import {Row, Col, Button, Form, Container, Spinner} from "react-bootstrap";
import {ImportDocumentsModal} from "./modals/importDocumentsModal";
import PacerMenu from "../Menu";
import AlertBox from "../../components/Alerts/AlertBox";
import {Stack, Typography} from "@mui/material";
import {countyList, lastUpdate, numberOfDocs, selectedCounty} from "../../store/signalsStore";
import {getCountyCollectionData} from "../../utils/queries/queryForCountyCollectionData";
import {updateCountyCollectionDataWithTotalNumberOfDocuments} from "../../utils/dbFunctions";
import {useSignals} from "@preact/signals-react/runtime";
import {AppState} from "../../App";
import {effect} from "@preact/signals-react";
import {DocTable, ImportResultsDocTable} from "./DocumentTable";


const Documents = () => {
    useSignals();
    const appState = useContext(AppState)
    const [documents, setDocs] = useState([]);
    const [document, setDocument] = useState({});
    const [numberOfDocuments, setNumberOfDocuments] = useState(0);
const numDocs = numberOfDocs.value

    //Import Modal related:
    const [showDuplicates, setShowDuplicates] = useState(false)
    const [replaceAll, setReplaceAll] = useState(false)
    const [showImportModal, setShowImportModal] = useState(false);
const [showUploadOptions, setShowUploadOptions] = useState(false)

    const handleReplaceAll = () => setReplaceAll(!replaceAll)
    const handleShowDuplicates = () => setShowDuplicates(!showDuplicates)
    const handleCloseImportModal = () => {
        setShowImportModal(false)
    };
    const handleShowImportModal = () => setShowImportModal(true);


    //Upload folder related
    const [county, setCounty] = useState(undefined)

    useEffect(() => {
        getCountyCollectionData(selectedCounty.value, setDocs)
    }, [selectedCounty.value]);



    return (
        <Container fluid>
            <Row>
                <PacerMenu/>
            </Row>
            <Row>
                <Stack>

                <Col xs={6}>
                    <Stack direction={"row"}>

                    <Button
                        className="m-3"

                        onClick={() => {
                            appState.alertMessage.value = "Starting import";
                            appState.alertSeverity.value = "info";
                            appState.alertProgress.value = 10;
                            handleShowImportModal()
                        }}
                    >
                        Import Documents
                    </Button>

                    {appState.showImportResults.value ? <Button
                        className="m-3"
                        variant="secondary"
                        onClick={() => {
                        appState.tableRows.value = appState.importStats.value.added
                    }}>Show Results</Button> : <></>}
                        {/*{appState.showImportResults.value ? <Button*/}
                        {/*    className="m-3"*/}
                        {/*    variant="secondary"*/}
                        {/*    onClick={() => {*/}
                        {/*        console.log(appState.importStats.value.updated)*/}
                        {/*        appState.tableRows.value = appState.importStats.value.added*/}
                        {/*        console.log(appState.tableRows.value)*/}
                        {/*    }}>Export Results</Button> : <></>}*/}
                    </Stack>

                </Col>
                <Col xs={2}>
                    <Button
                        className="m-3"

                        onClick={() => {
                            setShowUploadOptions(!showUploadOptions)
                        }}
                    >
                        {showUploadOptions !== true ? "Upload PDFS" : "Hide PDF Options"}
                    </Button>
                    </Col>
                </Stack>



            </Row>
            {showUploadOptions ?
            <Row style={{marginTop: "5px"}}>
                <Col>


                    <Row style={{margin: "10px"}}>
                        <Col xs={2}>

                            <Form.Label>{"Select County:"}</Form.Label>
                            <Form.Select
                                name={"county"}
                                onChange={(e) => {
                                    numberOfDocs.value = undefined
                                        setCounty(e.target.value)
                                        selectedCounty.value = e.target.value;
                                    appState.alertMessage.value = "Selected " + e.target.value;
                                    appState.alertSeverity.value = "info";
                                }}
                            >

                                {countyList.value.map((county, index) => {
                                    return (
                                        <option key={index}>{county}</option>)
                                })

                                }
                            </Form.Select>
                        </Col>
                    </Row>

                    <Button
                        variant="secondary"
                        style={{margin: "10px"}} onClick={() => {
                        if (county !== undefined) {
                        window.open(`https://console.firebase.google.com/project/documentdatabase-d2450/storage/documentdatabase-d2450.appspot.com/files/Counties/${county}`)
                        }
                        else {
                            appState.alertMessage.value = "Please Select a County";
                            appState.alertSeverity.value = "warning"
                        }
                    }}>Open Documents Folder</Button>

                    {county ?
                        <>
                        <Typography>For: {county}</Typography>


                        {/*{lastUpdate.value !== undefined ? <Typography>Last Update: {lastUpdate.value.toDateString()}</Typography> : <></>}*/}
                        {/*{ numberOfDocs.value !== undefined ?*/}
                        {/*<Typography>Documents: {numberOfDocs.value}</Typography> :*/}
                        {/*    <></>*/}
                        {/*}*/}

                        </>
                        :
<></>
                        }
                </Col>
            </Row>
                    : <></>}
                <ImportResultsDocTable appState={appState}/>
            {ImportDocumentsModal(
                showImportModal,
                handleCloseImportModal,
                document,
                setDocument,
                setShowImportModal,
                showDuplicates,
                handleShowDuplicates,
                replaceAll,
                handleReplaceAll,
                appState
            )
            }
        </Container>)
};
export default Documents;