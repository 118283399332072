import {Col, Row, Nav, Navbar, NavDropdown, Button} from "react-bootstrap";
import {useAuth} from "../contexts/AuthContext"
import {getPermissions} from "../utils/functions";
import LogoImageComponent from "./pacer_site/Components/LogoImageComponent";
import IndustryNavMenu from "./pacer_site/Components/IndustryNavMenu";
import {useLocation} from "react-router-dom";
import AboutNavMenu from "./pacer_site/Components/AboutNavMenu";
import AlertBox from "../components/Alerts/AlertBox";
import React, {useState} from "react";

const PacerMenu =() => {

    const { currentUser, logout} = useAuth();
    const location = useLocation();

    // const [alert, setAlert] = useState({message: "a bit longer message for spacing", severity: "success"});
    // const [progress, setProgress] = useState(50);
    return (
        <Row className="menu">

           <Col>
            <Navbar  style={{marginLeft: "10px"}} expand="xlg">
                <Navbar.Toggle aria-controls="basic-navbar-nav"/>
                <Navbar.Offcanvas id="basic-navbar-nav">
                    <Nav style={{marginLeft: "10px"}} className="me-auto fontstyle">
                        <Nav.Link href="/pacerhome">Home</Nav.Link>
                        <NavDropdown  title="Industries" id="basic-nav-dropdown">
                            <NavDropdown.Item href="/oilandgas">Oil and Gas</NavDropdown.Item>
                            <NavDropdown.Item href="/alternativeenergy">
                                Alternative Energy
                            </NavDropdown.Item>
                            <NavDropdown.Item href="/mining">Mining</NavDropdown.Item>
                            <NavDropdown.Item href="/rightofway">
                                Right Of Way
                            </NavDropdown.Item>
                            <NavDropdown.Item href="/digitalimaging">
                                Digital Imaging
                            </NavDropdown.Item>
                            <NavDropdown.Item href="/droneservices">
                                Drone Services
                            </NavDropdown.Item>
                        </NavDropdown>
                        <NavDropdown title="About" id="basic-nav-dropdown">
                            <NavDropdown.Item href="/management">Management</NavDropdown.Item>
                            <NavDropdown.Item href="/contactus">
                                Contact Us
                            </NavDropdown.Item>
                            <NavDropdown.Item href="/resources">Resources</NavDropdown.Item>
                        </NavDropdown>
                        {currentUser ? !getPermissions().isClient ?
<>
                                <NavDropdown title="Admin" id="basic-nav-dropdown">
                                    <NavDropdown.Item href="/table">Imports</NavDropdown.Item>
                                    <NavDropdown.Item href="/abstract">Abstracts</NavDropdown.Item>
                                    {getPermissions().admin ? <NavDropdown.Item href="/admin/addpaceruser">Admin</NavDropdown.Item>: <></>}
                                </NavDropdown>
</>

                            :
                            <NavDropdown title="Admin" id="basic-nav-dropdown">
                                <NavDropdown.Item href="/clienthome">Reports</NavDropdown.Item>
                                {getPermissions().admin ? <NavDropdown.Item href="/admin/adduser">Admin</NavDropdown.Item>: <></>}
                            </NavDropdown>
                            :
                            <Nav.Link href="/pacerportal" >Login</Nav.Link>
                        }
                        {currentUser ? <Button onClick={() => {
                            logout()
                        }}>Logout</Button> : <></>}
                    </Nav>
                </Navbar.Offcanvas>
            </Navbar>

                </Col>


        <Col xs={4}>
            <LogoImageComponent/>
            {location.pathname !== '/oilandgas' &&
            location.pathname !== '/mining' &&
            location.pathname !== '/alternativeenergy' &&
            location.pathname !== '/digitalimaging' &&
            location.pathname !== '/rightofway' &&
            location.pathname !== '/droneservices'&&
            location.pathname !== '/management' &&
            location.pathname !== '/contactus' &&
            location.pathname !== "/resources"
                ?
                <AlertBox />
                :
                <></>}

        </Col>
        <Col></Col>

            {location.pathname === '/oilandgas' ||
            location.pathname === '/mining' ||
            location.pathname === '/alternativeenergy' ||
            location.pathname === '/digitalimaging' ||
            location.pathname === '/rightofway' ||
            location.pathname === '/droneservices'
                ?
            <IndustryNavMenu/>
                :
                <></>}

            {location.pathname === '/management' || location.pathname === '/contactus' || location.pathname === "/resources" ?
                <AboutNavMenu/>
                :
                <></>}
    </Row>
    )
}

export default PacerMenu;