import {collection, getDocs, query, where} from "firebase/firestore";
import {db} from "../../services/firebase_config/firebase";
import {numberOfDocs} from "../../store/signalsStore";

export const getFullListForCounty = async (county) => {
    const collectionRef = collection(db, "Documents");
    const countyDataQuery = query(collectionRef,
        where("county", "==", county));
    const querySnapshot = await getDocs(countyDataQuery)
    if (!querySnapshot.empty) {
        let queryResult = querySnapshot.docs.map(doc => ({...doc.data(), id: doc.id}));
        if (queryResult[0] !== undefined) {
            numberOfDocs.value = queryResult.length
        }
    }

}