import React, {useState, useEffect} from "react";
import {addDoc, collection, doc, updateDoc} from 'firebase/firestore';
import {BiTrash} from "react-icons/bi";
import {Col, Row, Form, Button, Container} from 'react-bootstrap';
import {db} from "../../services/firebase_config/firebase";

import {emptyDocument} from "../../utils/containers";
import {onChange, addEditHandleDelete} from "../../utils/functions";
import {queriesForDatabaseSearch} from "../../utils/queries/QueriesForDatabaseSearch";


const AddEditDocuments = (props) => {
    const [documentDetails, setDocumentDetails] = useState(emptyDocument)

    const onSubmit = () => {
        if (documentDetails.documentNumber !== '') {documentDetails.document = documentDetails.documentNumber.toString()}
        if (documentDetails.book !== "") {documentDetails.document = documentDetails.book.toString().padStart(4, "0") + "-" + documentDetails.page.toString().padStart(4, "0") }
        if (documentDetails.id) {
            const docRef = doc(db, "Documents", `${documentDetails.id}`);
            updateDoc(docRef, documentDetails);
        } else {
            const collectionRef = collection(db, "Documents");
            addDoc(collectionRef, documentDetails).then(() => {
                return null
            });
        }
        props.handleClose()
    }

    const handleDatabaseSearchSubmit = (e) => {
        e.preventDefault();
        queriesForDatabaseSearch(props.searchQuery, props.searchType, props.setDocs)
    }

    useEffect(() => {
        if (props.document.id !== undefined || props.document.documentNumber !== undefined) setDocumentDetails(props.document)
    }, [props.show, props.document]);

    return (
        <Container>
            <Form noValidate>
                <Row>
                    <Col>
                        <Row>
                            <Col>
                                <Form.Label>{"Document"}</Form.Label>
                            </Col>
                            <Col>
                                <Row>
                                    {props.document.id ? <>
                                            <Col></Col>
                                            <Col></Col>
                                            <Col>
                                                <BiTrash
                                                    onClick={(e) => {
                                                        addEditHandleDelete("Documents", props.document.id, props.handleClose)
                                                        handleDatabaseSearchSubmit(e)
                                                    }}/>
                                            </Col>
                                        </>
                                        : <></>}
                                </Row>
                            </Col>
                        </Row>
                        <Form.Control
                            type={"text"}
                            placeholder={"Document"}
                            name={"Document"}
                            value={props.document.document}
                            onChange={(e) => onChange(e, props.document, setDocumentDetails)}
                            isInvalid={false}
                        />
                        <Form.Label>{"Instrument"}</Form.Label>
                        <Form.Control
                            type={"text"}
                            placeholder={"Document Type"}
                            name={"instrument"}
                            value={props.document.instrument}
                            onChange={(e) => onChange(e, props.document, setDocumentDetails)}
                            isInvalid={false}
                        />

                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Label>{"Grantor"}</Form.Label>
                        <Form.Control
                            as="textarea" rows={3}
                            type={"text"}
                            placeholder={"name of grantor"}
                            name={"grantor"}
                            value={props.document.grantor}
                            onChange={(e) => onChange(e, props.document, setDocumentDetails)}
                            isInvalid={false}
                        />
                    </Col>
                    <Col>
                        <Form.Label>{"Grantee"}</Form.Label>
                        <Form.Control
                            as="textarea" rows={3}
                            type={"text"}
                            placeholder={"name of grantee"}
                            name={"grantee"}
                            value={props.document.grantee}
                            onChange={(e) => onChange(e, props.document, setDocumentDetails)}
                            isInvalid={false}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Label>{"Effective Date"}</Form.Label>
                        <Form.Control
                            type={"text"}
                            placeholder={"enter date"}
                            name={"effectiveDate"}
                            value={props.document.effectiveDate}
                            onChange={(e) => onChange(e, props.document, setDocumentDetails)}
                            isInvalid={false}
                        />
                    </Col>
                    <Col>
                        <Form.Label>{"Executed Date"}</Form.Label>
                        <Form.Control
                            type={"text"}
                            placeholder={"enter date"}
                            name={"executedDate"}
                            value={props.document.executedDate}
                            onChange={(e) => onChange(e, props.document, setDocumentDetails)}
                            isInvalid={false}
                        />
                    </Col>
                    <Col>
                        <Form.Label>{"File Date"}</Form.Label>
                        <Form.Control
                            type={"text"}
                            placeholder={"enter date"}
                            name={"fileDate"}
                            value={props.document.fileDate}
                            onChange={(e) => onChange(e, props.document, setDocumentDetails)}
                            isInvalid={false}
                        />
                    </Col>
                </Row>
                <Row>
                    <Form.Label>{"Requisition Number"}</Form.Label>
                    <Form.Control
                        type={"number"}
                        placeholder={"enter requisistion number"}
                        name={"reqNumber"}
                        value={props.document.reqNumber}
                        onChange={(e) => onChange(e, props.document, setDocumentDetails)}
                        isInvalid={false}
                    />
                </Row>
                <Row>
                    <Col>
                        <Form.Label>{"Book"}</Form.Label>
                        <Form.Control
                            placeholder={"enter book"}
                            name={"book"}
                            type={"string"}
                            value={props.document.book}
                            onChange={(e) => onChange(e, props.document, setDocumentDetails)}
                            isInvalid={false}
                        />
                    </Col>
                    <Col>
                        <Form.Label>{"Page"}</Form.Label>
                        <Form.Control
                            type={"string"}
                            placeholder={"enter page number"}
                            name={"page"}
                            value={props.document.page}
                            onChange={(e) => onChange(e, props.document, setDocumentDetails)}
                            isInvalid={false}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Label>{"County"}</Form.Label>
                        <Form.Control
                            type={"text"}
                            placeholder={"enter county"}
                            name={"county"}
                            value={props.document.county}
                            onChange={(e) => onChange(e, props.document, setDocumentDetails)}
                            isInvalid={false}
                        />
                    </Col>
                </Row>
                <Form.Label>{"Description"}</Form.Label>
                <Form.Control
                    as="textarea" rows={3}
                    type={"text"}
                    placeholder={"enter description"}
                    name={"description"}
                    value={props.document.description}
                    onChange={(e) => onChange(e, props.document, setDocumentDetails)}
                    isInvalid={false}
                />
                <Form.Label>{"Comments"}</Form.Label>
                <Form.Control
                    as="textarea" rows={3}
                    type={"text"}
                    placeholder={"enter comments"}
                    name={"comments"}
                    value={props.document.comments}
                    onChange={(e) => onChange(e, props.document, setDocumentDetails)}
                    isInvalid={false}
                />
                <Button onClick={onSubmit}>Submit</Button>
            </Form>
        </Container>);
};

export default AddEditDocuments;