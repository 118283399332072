import {Button, Modal} from "react-bootstrap";
import React from "react";
import AddEditDocument from "../../pages/Database/AddEditDocument";
import {getDownloadURL, getStorage, ref} from "firebase/storage";
import {arrayUnion, doc, updateDoc} from "firebase/firestore";
import {db} from "../../services/firebase_config/firebase";

const storage = getStorage();
const rescan = (county, documentNumber, id, handleClose) => {
    console.log("checking for URL...")
    const fileRef = ref(storage, `/Counties/${county}/${documentNumber}.pdf`);
    getDownloadURL(fileRef).then((downloadURL) => {
            alert("url added!")
        const docRef = doc(db, "Documents", id);
        updateDoc(docRef, {downloadURL: downloadURL})
        const fileDocRef = doc(db, "driveData", county);
        updateDoc(fileDocRef, {
            files: arrayUnion({downloadURL: downloadURL, fileName: documentNumber + ".pdf"})
        })


        handleClose();
    }
    )

};
export const AddEditDocModal = (show, handleClose, document, setDocument, searchQuery, searchType, documents, setDocs) => {
    return (
        <Modal show={show} size="lg" onHide={handleClose}>
            <Modal.Header closeButton>
                {document.id === "" || document.id === undefined ?
                    <Modal.Title>Add Document</Modal.Title> :
                    <Modal.Title>Edit Document</Modal.Title>}
            </Modal.Header>
            <Modal.Body>
                <AddEditDocument document={document} handleClose={handleClose} searchQuery={searchQuery} searchType={searchType} setDocs={setDocs}/>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={() => {
                    rescan(document.county, document.documentNumber, document.id, handleClose)

                }
                }>Rescan for PDF</Button>
            </Modal.Footer>
        </Modal>
    )
}