import {addDoc, collection, deleteDoc, doc, getDocs, query, updateDoc} from "firebase/firestore";
import {db} from "../services/firebase_config/firebase";
import JSZip from "jszip";
import {saveAs} from "file-saver"
import axios from "axios";
import {getStorage, listAll, ref} from "firebase/storage";


const storage = getStorage();



export const onChange = (e, documentDetails, setDocumentDetails) => {
    const name = e.target.name
    if (name === "book" || name === "page" || name === "documentNumber") {
        documentDetails[name] = Number(e.target.value)
    } else {
    documentDetails[name] = e.target.value
    }
    setDocumentDetails({...documentDetails})
}

export const addEditHandleDelete = (collection, docId, handleClose) => {
    const collectionRef = doc(db, collection, docId);
    deleteDoc(collectionRef)
    handleClose()
}

export const ExcelDateToJSDate = (serial) => {
    const dateObject = new Date(Date.UTC(0, 0, serial));
    if (dateObject.toLocaleDateString() === "Invalid Date") {
        return ""
    }

    return dateObject.toLocaleDateString()
}


const storageURL = "https://storage.googleapis.com/documentdatabase-d2450.appspot.com"
export const getPDF = async (document) => {
    const pdfURL = `${storageURL}/Counties/${document.county}/${document.document}.pdf`
    return await axios({
        method: 'GET',
        url: pdfURL,
        responseType: 'arraybuffer',
    })
        .then((response) => response.data)
}

export const getFile = async (file, data) => {

    const fileURL = `${storageURL}/reportFiles/${data.header[0].client}/${data.header[0].project}/${file.name}`
    return await axios({
        method: 'GET',
        url: fileURL,
        responseType: 'arraybuffer',
    })
        .then((response) => response.data)
}

export const zipFiles = async (data, excel) => {
    data.setLoading({message: "bundling data", percentage: 1})
    const zip = new JSZip();
    data.list.forEach((document) => {
        zip.file("documents/" + document.document.toString() + ".pdf", getPDF(document));
    })
    data.files.forEach((file) => {
        zip.file("files/" + file.name, getFile(file, data));
    })
    zip.file(data.header[0].project + ".xlsx", excel);
    const content = await zip.generateAsync({type: "blob"}, function updateCallback(metadata) {
        data.setLoading({
            message: "creating zip file: " + metadata.currentFile,
            percentage: metadata.percent.toFixed(2)
        })
    });
    data.setLoading({message: "", percentage: 0})
    saveAs(content, data.header[0].project + ".zip");
}

export const getPermissions = () => {
    let userData = JSON.parse(localStorage.getItem("userData"))
    if (userData === null) userData = {client: false}
    return userData
}
export const getClientId = () => {
    let userData = JSON.parse(localStorage.getItem("userData"))
    if (userData === null) userData = {client: false}
    return userData.clientId
}

export const getClientNameFromLocalStorage = () => {
    let name = JSON.parse(localStorage.getItem("clientName"))
    if (name === null) name = ""
    return name
}

export const addClient = async (e, clientName, address, phone)  => {
    if (e !== undefined) e.preventDefault()
    try {
        const clientData = {
            name: clientName,
            address: address,
            phone: phone
        };

        const collectionRef = collection(db, "clientData");
        addDoc(collectionRef, clientData).then((doc) => {
            return doc.id
        });


    } catch {
        // setAlert({severity: "error", message: "Failed"})
    }
}

// This function only results in the last document on the list???
// export const getAbstractDocs = async (document, setDocQueryResults, queryResults) => {
//     let docQueryResults = [];
//     const collectionRef = collection(db, "Documents");
//     const docQuery = query(collectionRef,
//         // where("book", '==', document[6]),
//         // where("page", "==", document[7]),
//         where("documentNumber", "==", document.documentNumber),
//         where("county", "==", document.county));
//     const querySnapshot = await getDocs(docQuery)
//     if (!querySnapshot.empty) {
//         querySnapshot.docs.map(doc => {
//             queryResults = {...doc.data(), id: doc.id}
//         })
//     } else {
//         queryResults = document
//     }
//     docQueryResults.push(queryResults)
//     setDocQueryResults(docQueryResults)
// };

export const getCountyList = (setCountyList) => {
    const listRef = ref(storage, `Counties/`);
    const countyList = ["Select County"]
// Find all the prefixes and items. Returns number of files in folder and document numbers
    listAll(listRef)
        .then((res) => {
            res.prefixes.forEach((folderRef) => {
                let path = folderRef._location.path
                //let county = path.substring(4,3 )
                let county = path.substring(9, path.length );
                countyList.push(county)
            });
            // return docNumberArray
        }).catch(() => {
        // Uh-oh, an error occurred!
    });
    setCountyList(countyList)
}

export const addDocumentField = async () => {
    let queryResult;
    const collectionRef = collection(db, "Documents");

    const docQuery = query(collectionRef)

    const querySnapshot = await getDocs(docQuery)
    queryResult =  querySnapshot.docs.map(doc => ({...doc.data(), id: doc.id}));

    queryResult.forEach((dbdoc) => {
        let docString
        dbdoc.book = ""
        dbdoc.page = ""
        if (dbdoc.documentNumber !== undefined || dbdoc.documentNumber !== "") {docString = dbdoc.documentNumber.toString()}
           if (Number(dbdoc.book)) {
               docString = dbdoc.book.toString().padStart(4, "0") + "-" + dbdoc.page.toString().padStart(4, "0")
           }
           dbdoc.document = docString
        console.log(docString)
        const docRef = doc(db, "Documents", dbdoc.id);
    updateDoc(docRef, dbdoc)

})
}