import React, {createContext} from "react";
import './App.css';
import {Routes, Route, Navigate, RouterProvider, createBrowserRouter} from 'react-router-dom';
import AddEditDocument from './pages/Database/AddEditDocument';
import AbstractTable from "./pages/Abstract/AbstractReport";
import Documents from './pages/Database/Documents'
import ClientAbstractReport from "./pages/Reports/Client/ClientAbstractReport";
import PacerLogin from "./pages/PacerLogin";
import {AuthProvider} from "./contexts/AuthContext";
import PrivateRoute from "./contexts/PrivateRoute";
import PacerHome from "./pages/pacer_site/Pacer_Home";
import OilAndGas from "./pages/pacer_site/Industry_Cards/OilAndGas"
import AlternativeEnergy from "./pages/pacer_site/Industry_Cards/alternativeenergy";
import DigitalImaging from "./pages/pacer_site/Industry_Cards/digital_imaging";
import ROW from "./pages/pacer_site/Industry_Cards/row";
import Mining from "./pages/pacer_site/Industry_Cards/mining"
import DroneServices from "./pages/pacer_site/Industry_Cards/droneservices";
import Management from "./pages/pacer_site/About_Cards/Management";
import ContactUs from "./pages/pacer_site/About_Cards/Contact_Us";
import Resources from "./pages/pacer_site/About_Cards/Resources";
import Blog from "./pages/pacer_site/Blog";
import Events from "./pages/pacer_site/Events";
import ClientHome from "./pages/Reports/Client/ClientHome";
import AdminViewClientAbstractReport from "./pages/Reports/AdminViewClientAbstractReport";
import Admin from "./pages/Admin/UserManagement/Admin";
import SelectClient from "./components/SelectClient";
import {createAppState} from "./contexts/signalState";

export const AppState = createContext(createAppState());

function App() {
    return (
        <>
            <AuthProvider>
                <AppState.Provider value={createAppState()}>
                <Routes>
                    <Route path="/" element={<Navigate replace to="/pacerhome"/>}/>
                    <Route path="Add" element={<PrivateRoute><AddEditDocument/></PrivateRoute>}/>
                    <Route path="abstract" element={<PrivateRoute><AbstractTable/></PrivateRoute>}/>
                    <Route path="abstract/:id" element={<PrivateRoute><AbstractTable/></PrivateRoute>}/>
                    <Route path="selectClient" element={<PrivateRoute><SelectClient/></PrivateRoute>}/>
                    <Route path="report/" element={<PrivateRoute><ClientAbstractReport/></PrivateRoute>}/>
                    <Route path="report/:id" element={<PrivateRoute><ClientAbstractReport/></PrivateRoute>}/>
                    <Route path="adminreport/" element={<PrivateRoute><AdminViewClientAbstractReport/></PrivateRoute>}/>
                    <Route path="adminreport/:id" element={<PrivateRoute><AdminViewClientAbstractReport/></PrivateRoute>}/>
                    <Route path="table" element={<PrivateRoute><Documents/></PrivateRoute>}/>
                    <Route path="clienthome" element={<PrivateRoute><ClientHome/></PrivateRoute>}/>
                    <Route path="admin/*" element={<PrivateRoute><Admin/></PrivateRoute>}/>
                    <Route path="pacerportal" element={<PacerLogin/>}/>
                    <Route path="pacerhome" element={<PacerHome/>}/>
                    <Route path="oilandgas" element={<OilAndGas/>}/>
                    <Route path="alternativeenergy" element={<AlternativeEnergy/>}/>
                    <Route path="digitalimaging" element={<DigitalImaging/>}/>
                    <Route path="rightofway" element={<ROW/>}/>
                    <Route path="mining" element={<Mining/>}/>
                    <Route path="droneservices" element={<DroneServices/>}/>
                    <Route path="management" element={<Management/>}/>
                    <Route path="contactus" element={<ContactUs/>}/>
                    <Route path="resources" element={<Resources/>}/>
                    <Route path="blog" element={<Blog/>}/>
                    <Route path="events" element={<Events/>}/>
                </Routes>
                </AppState.Provider>
            </AuthProvider>
        </>
    );
}

export default App;
