import {collection, getDocs, query, where} from "firebase/firestore";
import {db} from "../../services/firebase_config/firebase";
import {getDocListHeaderDetails} from "../../pages/Database/functions/getHeaderInfo";
import {headerDetailsSignal} from "../../store/signalsStore";


export const queryForDocListDocuments = (
    rows,
    setDocQueryResults,
) => {
    const docList = [];
    const docQueryResults = [];
    let documentString = "";
   getDocListHeaderDetails(rows);

    rows.map((row) => {
        if (row[0] !== "Project" &&
            row[0] !== "County" &&
            row[0] !== "TRS" &&
            row[0] !== "Section" &&
            row[0] !== "Date Range for Report" &&
            row[0] !== "Acreage" &&
            row[0] !== "Document Number" &&
            row[0] !== "Last Page" &&
            row[0] !== "Client" &&
            row[0] !== "Doc #"
        ) {

            if (row[0] !== undefined || row[1] !== undefined ) {

            let paddedBook = "";
            let paddedPage = "";

            if (!row[0]) {
                if (headerDetailsSignal.value.county === "Cheyenne County, Nebraska") {
                    paddedBook = row[1].toString().padStart(5, "0");
                    paddedPage = row[2].toString().padStart(4, "0");
                    documentString = paddedBook + "-" + paddedPage;
                } else {

                paddedBook = row[1].toString().padStart(4, "0");
                paddedPage = row[2].toString().padStart(4, "0");
                documentString = paddedBook + "-" + paddedPage;
                }
            } else { documentString = row[0].toString()
            }
            docList.push({
                document: documentString.toUpperCase(),
                county: headerDetailsSignal.value.county,
                book: paddedBook.toUpperCase() ?? "",
                page: paddedPage.toUpperCase()
            })
            }
            }

        return null
    })
    // setShowImportOption(false);
    docList.forEach((doc) => {
        let queryResults = {};
        const getAbstractDocs = async (document, setDocQueryResults) => {
            const collectionRef = collection(db, "Documents");
            const docQuery = query(collectionRef,
                where("document", "==", document.document),
                where("county", "==", document.county));
            const querySnapshot = await getDocs(docQuery)
            if (!querySnapshot.empty) {
                querySnapshot.docs.map(doc => {
                    queryResults = {...doc.data(), id: doc.id}
                    return null
                })
            } else {
                queryResults = document
            }
            docQueryResults.push(queryResults)
            setDocQueryResults(docQueryResults)
        };


        if (doc !== undefined) {
            getAbstractDocs(doc, setDocQueryResults, queryResults).then(() => {});
        }
    })
};

